import { useQuery } from "@tanstack/vue-query";
import type {
  ExamLevelsRecord,
  ExamTypesRecord,
  WitchDressesRecord,
} from "~/types/pocketbase-types";

export type Archievment = {
  name: string;
  maxLevel: number;
  currentLevel: number;
  image: string;
};

export function useWitchSchool(witchId: MaybeRefOrGetter<string>) {
  const { pocketbase } = storeToRefs(usePocketBase());
  const witchIdRef = toRef(witchId);

  const { data: currentDress } = useQuery({
    queryKey: ["witch_dresses", witchIdRef],
    select: (dress) => ({
      ...dress,
      image: pocketbase.value.getFileUrl(dress, dress.image),
    }),
    queryFn: () =>
      pocketbase.value
        .collection("witch_dresses")
        .getFirstListItem<WitchDressesRecord>(`witch="${witchIdRef.value}"`, {
          expand: "exam_type",
          sort: `-exam_type.grade`,
        }),
  });

  const { data: examTypes } = useQuery({
    queryKey: ["exam_types"],
    queryFn: () =>
      pocketbase.value.collection("exam_types").getFullList<ExamTypesRecord>(),
  });

  const { data: examLevelsOfWitch } = useQuery({
    queryKey: ["exam_levels", witchIdRef, examTypes],
    queryFn: async () =>
      await pocketbase.value
        .collection("exam_levels")
        .getFullList<ExamLevelsRecord>({
          filter: `witches_passed~"${witchIdRef.value}"`,
          expand: "exam_type",
        }),
  });

  const examStatisticNew = computed<Archievment[]>(() => {
    return (
      examTypes.value?.map((type: ExamTypesRecord) => {
        const exams: ExamLevelsRecord[] =
          examLevelsOfWitch.value?.filter(
            (examLevel) => examLevel.exam_type === type.id,
          ) ?? [];
        if (exams.length === 0) {
          return {
            name: type.name,
            maxLevel: type.levels,
            currentLevel: 0,
            image: pocketbase.value.getFileUrl(
              type,
              type.base_archievment_image,
            ),
          };
        }

        const record: ExamLevelsRecord = exams.reduce((prev, current) =>
          prev && prev.level > current.level ? prev : current,
        );

        return {
          name: type.name,
          maxLevel: type.levels,
          currentLevel: record.level,
          image: pocketbase.value.getFileUrl(record, record.archievment_image),
        };
      }) ?? []
    );
  });

  return {
    examStatisticNew,
    currentDress,
  };
}
